<template>
    <a-layout id="theLayout">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
            <div class="logo">轻舟能科官网后台</div>
            <a-menu
                theme="dark"
                mode="inline"
                v-model="selectedKey"
                @click="menuClick"
            >
                <a-menu-item key="/dataTotal">
                    <a-icon type="bar-chart" />
                    <span>数据统计</span>
                </a-menu-item>
                <a-menu-item key="/news">
                    <a-icon type="profile" />
                    <span>新闻管理</span>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0">
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="() => (collapsed = !collapsed)"
                />
                <div class="dropdown">
                    <a-dropdown>
                        <a
                            class="ant-dropdown-link"
                            @click="(e) => e.preventDefault()"
                        >
                            {{ username }} <a-icon type="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item>
                                <a
                                    href="https://www.skiffenergy.com/home"
                                    target="_blank"
                                    >轻舟能科</a
                                >
                            </a-menu-item>
                            <a-menu-item @click="logout">
                                <a href="javascript:;">退出</a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
            </a-layout-header>
            <a-layout-content
                :style="{
                    margin: '24px 16px',
                    padding: '24px',
                    background: '#fff',
                    minHeight: '280px',
                }"
            >
                <h1 style="margin-bottom: 16px; font-weight: bold">
                    {{ $route.meta.title }}
                </h1>
                <slot></slot>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
export default {
    name: "theLayout",
    data() {
        return {
            username: "admin",
            collapsed: false,
            selectedKey: ["dataTotal"],
        };
    },
    watch: {
        $route() {
            this.setSelectedKey();
        },
    },
    mounted() {
        this.username =
            JSON.parse(localStorage.getItem("userInfo") || "{}").username ||
            "admin";
        this.setSelectedKey();
    },
    methods: {
        // menu高亮设置
        setSelectedKey() {
            let key = this.$route.meta.highLight || this.$route.path;
            if (this.selectedKey[0] === key) return;
            this.selectedKey = [key];
        },
        // menu点击
        menuClick({ key }) {
            this.$router.push({ path: `${key}` });
        },
        logout() {
            this.$axios.post("/user/login-out").then(() => {
                this.$message.success("成功退出");
                this.$router.push({ name: "login" });
            });
        },
    },
};
</script>
<style lang="scss">
#theLayout {
    .ant-layout {
        min-height: 100vh;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .dropdown {
                padding: 0 24px;
            }
        }
    }
    .logo {
        height: 32px;
        margin: 16px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .trigger {
        &:hover {
            color: #1890ff;
        }
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }
}
</style>
