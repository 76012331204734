import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: {
			path: "/login"
		}
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: "登录页"
		},
		component: () => import("@/views/login")
	},
	{
		path: "/dataTotal",
		name: "dataTotal",
		meta: {
			title: "流量分析"
		},
		component: () => import("@/views/data")
	},
	{
		path: "/news",
		name: "news",
		meta: {
			title: "新闻管理"
		},
		component: () => import("@/views/news")
	},
	{
		path: "/news/edit",
		name: "news-edit",
		meta: {
			title: "文章编辑",
			highLight: "/news"
		},
		component: () => import("@/views/news-edit")
	}
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (to.name === "login") {
		localStorage.clear();
		sessionStorage.clear();
	}
	next();
});

router.afterEach(() => {
	window.scrollTo(0, 0);
});

export default router;
